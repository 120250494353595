import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StateService } from '@uirouter/core';

import { ActionService } from '@services/utils/action.service';
import { GroupLoginService } from '@services/login/group-login.service';
import { HardwareService } from '@services/hardware/hardware.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { TranslationService } from '@services/utils/translation.service';

import { ProductModule } from '@models/core/product-module';
import { ChargeModelTypeName } from '@models/core/hospital-settings';
import { FeatureFlagService } from '@services/system/feature-flag/feature-flag.service';

export enum ModuleTypes {
    INVENTORY = 'inventory',
    TAGGING = 'tagging',
    REPORTING = 'reporting',
    HELP = 'help',
}

@Injectable()
export class ProductModuleService {
    private modulesObserver: BehaviorSubject<ProductModule[]> = new BehaviorSubject(null);
    private availableModules: ProductModule[] = [];
    private allModules: ProductModule[] = [];
    private standardModules: string[] = [ModuleTypes.INVENTORY, ModuleTypes.REPORTING];
    private printerless: boolean = true;
    private isContainersEnabled: boolean;

    constructor(
        private actionService: ActionService,
        private groupLoginService: GroupLoginService,
        private hardwareService: HardwareService,
        private hospitalInfoService: HospitalInfoService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private $state: StateService,
        private translationService: TranslationService,
        private featureFlagService: FeatureFlagService
    ) {
        this.featureFlagService.getFeatureValue('use-containers').subscribe((useContainers) => {
            this.isContainersEnabled = useContainers;
        });

        this.buildAllModules();
    }

    observeModules(): Observable<any> {
        return this.modulesObserver;
    }

    processModules(): Promise<any> {
        this.standardModules.forEach((standardModuleName: string) => {
            const module = this.allModules.find((m) => m.name === standardModuleName);
            this.setModuleTranslations(module, standardModuleName);
        });

        this.allModules.forEach((module: ProductModule) => {
            const dropdownAllowed: boolean = !!module.dropdown.items.find((item) => item.allowed());

            module.dropdown.allowed = dropdownAllowed;
        });

        this.availableModules = this.allModules.filter((m) => this.isModuleAllowed(m.name));

        return this.hardwareService
            .getPrinters()
            .then((printers: any[]) => {
                this.printerless = printers.length === 0;

                this.configureTaggingModule();
                this.setDropdownItemTranslations();
                this.modulesObserver.next(this.getModules());
            })
            .catch((err) => {});
    }

    setModule(moduleName: string = '') {
        if (!this.groupLoginService.isLoggedIn() && !this.groupLoginService.requirePassword()) {
            this.$state.go('login');
        }
        const active = this.availableModules.find((m) => m.isActive);
        if (active) {
            active.isActive = false;
        }
        if (moduleName !== '') {
            this.processModules().finally(() => {
                if (!this.isModuleAllowed(moduleName)) {
                    this.$state.go('home');
                } else {
                    this.getModule(moduleName).isActive = true;
                }
            });
        }

        this.kcMatSnackBarService.clearAll();
    }

    getModule(moduleName: string): ProductModule {
        return this.availableModules.find((m) => m.name === moduleName);
    }

    getModules(): ProductModule[] {
        return this.availableModules;
    }

    private setDropdownItemTranslations() {
        const taggingPrintKey = this.printerless ? 'printerless.' : 'printered.';

        this.allModules.forEach((module) => {
            const moduleName = module.name;
            module.dropdown.items.forEach((dropdownItem) => {
                const dropdownKey = dropdownItem.titleKey;
                const taggingAdd =
                    moduleName === 'tagging' && dropdownKey !== 'pre_tagged_medications' ? taggingPrintKey : '';
                this.translationService
                    .get(`header.dropdown.${moduleName}.${taggingAdd}${dropdownKey}`)
                    .then((text) => {
                        dropdownItem.title = text;
                    });
            });
        });
    }

    private configureTaggingModule() {
        const tagging = this.allModules.find((m) => m.name === ModuleTypes.TAGGING);

        const binsEnabled = this.hospitalInfoService.allowShelvedInventory();
        if (binsEnabled && this.printerless) {
            tagging.hideMenu = false;
            this.setModuleTranslations(tagging, 'tagging_bins_printerless', true);
        } else if (binsEnabled) {
            tagging.hideMenu = false;
            this.setModuleTranslations(tagging, 'tagging', true);
        } else if (!binsEnabled && this.printerless) {
            tagging.hideMenu = false;
            this.setModuleTranslations(tagging, 'tagging_binless_printerless', true);
        } else if (!binsEnabled) {
            tagging.hideMenu = false;
            this.setModuleTranslations(tagging, 'tagging_binless_printered', true);
        }

        this.translationService.get('home.modules.tagging.title').then((text) => {
            tagging.title = text;
        });
    }

    private setModuleTranslations(module: ProductModule, translationKey: string, startPageTitle: boolean = false) {
        this.translationService
            .get([
                `home.modules.${translationKey}.start_page_title`,
                `home.modules.${translationKey}.title`,
                `home.modules.${translationKey}.slogan`,
                `home.modules.${translationKey}.description`,
            ])
            .then((translations) => {
                if (startPageTitle) {
                    module.startPageTitle = translations[`home.modules.${translationKey}.start_page_title`];
                } else {
                    module.title = translations[`home.modules.${translationKey}.title`];
                }

                module.slogan = translations[`home.modules.${translationKey}.slogan`];
                module.description = translations[`home.modules.${translationKey}.description`];
            });
    }

    private isModuleAllowed(module) {
        switch (module) {
            case ModuleTypes.INVENTORY:
                return this.actionService.isAllowAction(
                    'kits_inventory',
                    'view_kits_inventory',
                    'Enable inventory section'
                );
            case ModuleTypes.TAGGING:
                return this.actionService.isAllowAction('kits_tagging', 'view_kits_tagging', 'Enable tagging section');
            case ModuleTypes.REPORTING:
                return this.actionService.isAllowAction(
                    'kits_reporting',
                    'view_kits_reports',
                    'Enable reports section'
                );
            case ModuleTypes.HELP:
                return true;
            default:
                return false;
        }
    }

    private buildAllModules() {
        let inventory: ProductModule = {
            name: 'inventory',
            stateName: 'inventory',
            startPageTitleKey: 'settings.start_page.show_inventory',
            headerOnly: false,
            dropdown: {
                items: [
                    {
                        stateName: 'containers-index',
                        titleKey: 'containers',
                        allowed: () => {
                            return this.isContainersEnabled;
                        },
                    },
                    {
                        stateName: 'inventory',
                        titleKey: 'kits',
                        allowed: () => {
                            return this.actionService.isAllowAction(
                                'kits_inventory',
                                'view_kits_inventory',
                                'Kits dropdown'
                            );
                        },
                    },
                    {
                        stateName: 'bins-index',
                        titleKey: 'bins',
                        allowed: () => {
                            return (
                                this.hospitalInfoService.allowShelvedInventory() &&
                                this.actionService.isAllowAction('kits_inventory', 'view_bin', 'Bins dropdown')
                            );
                        },
                    },
                    {
                        stateName: 'inventoryCarts',
                        titleKey: 'carts',
                        allowed: () => {
                            return (
                                this.hospitalInfoService.getHospitalSettings().kit_to_cart_enabled &&
                                this.actionService.isAllowAction('hospital_settings', 'view_cart', 'Carts dropdown')
                            );
                        },
                    },
                    {
                        stateName: 'inventory-kit-masters-list',
                        titleKey: 'manage_kit_masters',
                        allowed: () => {
                            return this.actionService.isAllowAction(
                                'kits_inventory',
                                'view_kit_master',
                                'Kit masters dropdown'
                            );
                        },
                    },
                    {
                        stateName: 'formulary-index',
                        titleKey: 'manage_formulary',
                        allowed: () => {
                            return this.actionService.isAllowAction(
                                'hospital_settings',
                                'create_formulary_item',
                                'Manage Formulary dropdown'
                            );
                        },
                    },
                    {
                        stateName: 'inventory-manage-items',
                        stateParams: { epc: null },
                        titleKey: 'manage_items',
                        allowed: () => {
                            return this.actionService.isAllowAction(
                                'kits_tagging',
                                'view_item_tag',
                                'Manage Items dropdown'
                            );
                        },
                    },
                    {
                        stateName: 'shortageReports',
                        titleKey: 'shortage_report',
                        allowed: () => {
                            return this.actionService.isAllowAction(
                                'kits_inventory',
                                'view_kits_inventory',
                                'Kits dropdown'
                            );
                        },
                    },
                ],
            },
        };

        let tagging: ProductModule = {
            name: 'tagging',
            stateName: 'tagging',
            type: 'item',
            hideMenu: false,
            headerOnly: false,
            dropdown: {
                items: [
                    {
                        stateName: 'tagging',
                        type: 'item',
                        titleKey: 'items',
                        allowed: () => {
                            return this.actionService.isAllowAction(
                                'kits_tagging',
                                'create_item_tag',
                                'Print Item Tags dropdown'
                            );
                        },
                    },
                    {
                        stateName: 'tagging',
                        type: 'kit',
                        titleKey: 'kits',
                        allowed: () => {
                            return this.actionService.isAllowAction(
                                'kits_inventory',
                                'create_kit',
                                'Print Kit Tags dropdown'
                            );
                        },
                    },
                    {
                        stateName: 'tagging',
                        type: 'bin',
                        titleKey: 'bins',
                        allowed: () => {
                            return (
                                this.actionService.isAllowAction(
                                    'kits_inventory',
                                    'create_bin',
                                    'Print Bin Tags dropdown'
                                ) && this.hospitalInfoService.allowShelvedInventory()
                            );
                        },
                    },
                    {
                        stateName: 'pretagged-medications',
                        titleKey: 'pre_tagged_medications',
                        allowed: () => {
                            return (
                                this.hospitalInfoService.getHospitalSettings().charge_model_type?.name ===
                                ChargeModelTypeName.pay_as_you_go
                            );
                        },
                    },
                ],
            },
        };

        let reporting: ProductModule = {
            name: 'reporting',
            stateName: 'reports',
            startPageTitleKey: 'settings.start_page.reports',
            headerOnly: false,
            dropdown: {
                items: [
                    {
                        stateName: 'reports',
                        titleKey: 'reports',
                        allowed: () => {
                            return this.actionService.isAllowAction(
                                'kits_reporting',
                                'view_kits_reports',
                                'Reports dropdown'
                            );
                        },
                    },
                    {
                        stateName: 'system-reports',
                        titleKey: 'system_reports',
                        allowed: () => {
                            return (
                                this.hospitalInfoService.hasIdnRoot() &&
                                this.actionService.isAllowAction(
                                    'kits_reporting',
                                    'view_system_reports',
                                    'Reports dropdown'
                                )
                            );
                        },
                    },
                    {
                        stateName: 'manage-subscriptions',
                        titleKey: 'manage_subscriptions',
                        allowed: () => {
                            return (
                                this.hospitalInfoService.getHospitalSettings().scheduled_reports_enabled &&
                                this.actionService.isAllowAction(
                                    'kits_reporting',
                                    'view_kits_reports',
                                    'Reports dropdown'
                                )
                            );
                        },
                    },
                ],
            },
        };

        this.allModules = [inventory, tagging, reporting];
    }
}
