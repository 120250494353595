import { Component, Input } from '@angular/core';
import { StateService } from '@uirouter/core';

import { ActionService } from '@services/utils/action.service';
import { ApplicationService } from '@services/system/application.service';
import { HardwareService } from '@services/hardware/hardware.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { LocalStorageService } from '@services/storage/local-storage.service';
import { ProductModuleService } from '@services/core/product-module.service';
import { TranslationService } from '@services/utils/translation.service';

@Component({
    selector: 'home',
    templateUrl: './home.html',
    styleUrls: ['./home.scss'],
})
export class Home {
    firstName: string;

    constructor(
        protected actionService: ActionService,
        private applicationService: ApplicationService,
        protected hardwareService: HardwareService,
        protected localStorageService: LocalStorageService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private productModuleService: ProductModuleService,
        protected $state: StateService,
        protected translationService: TranslationService
    ) {}

    ngOnInit() {
        this.firstName = this.localStorageService.get('firstName');

        if (this.applicationService.isMobile) {
            // mobile user unintentionally navigated to desktop
            this.$state.go('mobile-cart.landing');
        }
        this.productModuleService.setModule();

        let transferredMessage = this.$state.params.transferredMessage;
        let newVerification = this.$state.params.newVerification;

        if (newVerification === 'scan-details') {
            this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                key: 'add_to_bin.items_were_added_to_bin',
                params: { bin: transferredMessage },
            });
        } else if (newVerification === 'scan-details-remove') {
            this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                key: 'add_to_bin.items_were_removed_from_bin',
                params: { bin: transferredMessage },
            });
        } else if (newVerification === 'batch-verify') {
            this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                key: 'verify_and_add_to_bin.items_were_verified_and_added_to_bin',
                params: { bin: transferredMessage },
            });
        } else if (newVerification === 'batch-verify-remove') {
            this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                key: 'verify_and_add_to_bin.items_were_verified_and_removed_from_bin',
                params: { bin: transferredMessage },
            });
        } else if (newVerification === 'verify') {
            if (transferredMessage) {
                this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                    key: 'batch_verify.your_items_have_been_verified_and',
                });
            } else {
                this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                    key: 'batch_verify.your_items_have_been_verified',
                });
            }
        }

        this.productModuleService.processModules();

        if (!this.$state.params.isBrandNav) {
            this.redirectToUserSetHomepage();
        }
    }

    redirectToUserSetHomepage() {
        const defaultPage = this.localStorageService.get('defaultPage');
        if (defaultPage) {
            const module = this.productModuleService.getModule(defaultPage);
            if (module) {
                this.$state.go(module.stateName);
            }
        }
    }
}
