import { Injectable } from '@angular/core';
import { compare, CompareOptions, Order /*, sort*/ } from 'natural-orderby';

const deep = require('object-path');

export enum SortDirection {
    asc = 'asc',
    desc = 'desc',
}

@Injectable({
    providedIn: 'root',
})
export class NaturalSortService {
    public instance: any;

    constructor() {
        // For compatibility with downgradeInjectable when using static methods.
        this.instance = {
            sort: NaturalSortService.sort,
            sortOnTwoFields: NaturalSortService.sortOnTwoFields,
        };
    }

    /**
     * Array sort function.
     *
     * @param values
     * @param field
     */
    static sort(values: Array<any>, field: string, sortDirection: SortDirection = SortDirection.asc): Array<any> {
        let sortDir: any = { order: sortDirection };
        let sorted = values.sort((a, b) => compare(sortDir)(deep.get(a, field), deep.get(b, field)));
        return sorted;
    }

    /**
     * Array sort function.
     *
     * @param values
     * @param field
     */
    static sortOnTwoFields(
        values: Array<any>,
        field1: string,
        field2: string,
        sortDirection1: SortDirection = SortDirection.asc,
        sortDirection2: SortDirection = SortDirection.asc
    ): Array<any> {
        let sortDir1: any = { order: sortDirection1 };
        let sortDir2: any = { order: sortDirection2 };
        let sorted = values.sort((a, b) => {
            if (deep.get(a, field1).localeCompare(deep.get(b, field1))) {
                return compare(sortDir1)(deep.get(a, field1), deep.get(b, field1));
            } else {
                return compare(sortDir2)(deep.get(a, field2), deep.get(b, field2));
            }
        });
        return sorted;
    }
}
