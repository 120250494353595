import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

import { ActionService } from '@services/utils/action.service';
import { ConfigurationProvider } from '@services/config/configuration';
import { GroupLoginService } from '@services/login/group-login.service';
import { HardwareResource } from '@resources/hardware-resource.service';
import { LocalStorageService } from '@services/storage/local-storage.service';
import { Scanner } from '@models/hardware/scanner';
import { MatDialog } from '@angular/material/dialog';

import { ScannerUpdateDialog } from '@components/dialogs/scanner/scanner-update-dialog/scanner-update-dialog';
import { ScannerUpdatingService } from '@services/system/scanner-updating.service';
import { ScannerService } from './scanner.service';

interface IHardwareCache {
    hardware?: any[];
    scanners?: Scanner[];
    printers?: any[];
    defaultScanner?: any;
    defaultPrinter?: any;
}

@Injectable()
export class HardwareService {
    cache: IHardwareCache = {};
    private hardwareUpdatePromptOpen = false;

    private onlineScanners: Scanner[] = [];
    private onlineScannersObserver: BehaviorSubject<Scanner[]> = new BehaviorSubject(null);

    constructor(
        private actionService: ActionService,
        private configuration: ConfigurationProvider,
        private groupLoginService: GroupLoginService,
        private hardwareResource: HardwareResource,
        private localStorageService: LocalStorageService,
        private scannerService: ScannerService,
        private scannerUpdatingService: ScannerUpdatingService,
        private dialog: MatDialog
    ) {}

    onLogin() {
        if (this.configuration.getConfiguration().environment !== 'buildkite') {
            this.populateOnlineScanners();
            this.checkForUpdatableScanners();
        }
    }

    getAllOnlineScanners(): Promise<any> {
        return new Promise((resolve) => {
            if (this.onlineScanners.length > 0) {
                resolve(this.onlineScanners);
            } else {
                this.observeOnlineScanners().subscribe((scanners: Scanner[]) => {
                    // scanners obj is null on app login
                    if (!!scanners) {
                        resolve(this.onlineScanners);
                    }
                });
            }
        });
    }

    getHardware() {
        return this.getData('hardware');
    }

    getPrinters() {
        return this.getData('printers');
    }

    getScanners() {
        return this.getData('scanners');
    }

    getDefaultScanner() {
        return this.getData('defaultScanner');
    }

    getDefaultPrinter() {
        return this.getData('defaultPrinter');
    }

    clear() {
        delete this.cache.hardware;
        delete this.cache.scanners;
        delete this.cache.printers;
        delete this.cache.defaultScanner;
        delete this.cache.defaultPrinter;
    }

    setDefaultScanner(id) {
        this.localStorageService.set('default.scannerId', id);
        if (this.cache.scanners) {
            this.cache.defaultScanner = _.find(this.cache.scanners, {
                id: parseInt(id),
            });
        }
    }

    setDefaultPrinter(id) {
        this.localStorageService.set('default.printerId', id);
        if (this.cache.printers) {
            this.cache.defaultPrinter = _.find(this.cache.printers, {
                id: parseInt(id),
            });
        }
    }

    updateHardware(hardware) {
        return this.hardwareResource.updateHardware(hardware).then(() => {
            return this.clear();
        });
    }

    // perhaps this can be simplified to utilize the allOnlineScanners output
    getAnOnlineScanner(timeoutms = 5 * 1000) {
        return this.getScanners().then((scanners) => {
            if (scanners.length > 0) {
                return new Promise((_resolve) => {
                    //resolve with null if not resolved in `timeoutms`
                    let didTimeout = false;
                    let timeout = setTimeout(() => {
                        didTimeout = true;
                        _resolve(null);
                    }, timeoutms);

                    //only allow resolve to be called once
                    let resolve = (result) => {
                        if (!didTimeout) {
                            clearTimeout(timeout);
                            _resolve(result);
                            _resolve = () => {};
                        }
                    };

                    //resolve with null if all scanners are offline
                    let offlineCount = 0;
                    let scannerIsOffline = () => {
                        offlineCount++;
                        if (offlineCount === scanners.length) {
                            resolve(null);
                        }
                    };

                    scanners.forEach((s) => {
                        if (s.online === 'YES') {
                            resolve(s);
                        } else if (!s.online) {
                            this.scannerService.checkStatus(s).then(() => {
                                if (s.online === 'YES') {
                                    resolve(s);
                                } else {
                                    scannerIsOffline();
                                }
                            });
                        } else {
                            scannerIsOffline();
                        }
                    });
                });
            } else {
                return Promise.resolve(null);
            }
        });
    }

    private showUpdateModal(allActiveScanners) {
        this.groupLoginService.getRefreshedHospitalSettings().then((refreshedSettings) => {
            const hardwareUpdatePrompt = refreshedSettings.hardware_update_prompt;

            if (hardwareUpdatePrompt && !this.scannerUpdatingService.isUpdating && !this.hardwareUpdatePromptOpen) {
                const updatableScanners = _.filter(
                    allActiveScanners,
                    (s: any) => s.online === 'UPGRADE' || s.online === 'UPGRADE_SSL'
                );
                if (updatableScanners.length) {
                    this.hardwareUpdatePromptOpen = true;
                    const scannerUpdateDialog = this.dialog.open(ScannerUpdateDialog, {
                        width: '800px',
                        height: 'max-content',
                        data: { scanners: updatableScanners },
                    });

                    scannerUpdateDialog.afterClosed().subscribe((scanner) => {
                        this.hardwareUpdatePromptOpen = false;
                        if (!!scanner) {
                            if (scanner.online === 'UPGRADE_SSL') {
                                this.scannerService.updateSSL(scanner);
                            } else {
                                this.scannerService.updateFirmware(scanner);
                            }
                        }
                    });
                }
            }
        });
    }

    private observeOnlineScanners(): Observable<Scanner[]> {
        return this.onlineScannersObserver;
    }

    private checkForUpdatableScanners() {
        return this.getAllOnlineScanners().then((activeScanners) => {
            this.showUpdateModal(activeScanners);
        });
    }

    private populateOnlineScanners() {
        return new Promise((resolve) => {
            let activeScanners = [];
            this.getScanners()
                .then((scanners) => {
                    let promiseList = [];
                    scanners.forEach((s) => {
                        let statusProm = this.scannerService.checkStatus(s).then(() => {
                            if (s.online === 'YES' || s.online === 'UPGRADE' || s.online === 'UPGRADE_SSL') {
                                activeScanners.push(s);
                            }
                        });
                        promiseList.push(statusProm);
                    });
                    return Promise.all(promiseList);
                })
                .then(() => {
                    this.onlineScanners = activeScanners;
                    this.onlineScannersObserver.next(this.onlineScanners);
                    resolve(this.onlineScanners);
                });
        });
    }

    fillHardwareAttributes(obj) {
        obj.value = obj.ip4_port
            ? `${obj.protocol}://${obj.hostname}:${obj.ip4_port}`
            : `${obj.protocol}://${obj.hostname}`;

        if (obj.make === 'ThingMagic' || obj.model === '9600') {
            const secure = !!obj.value.match(/^https/);
            const safePort = secure ? '8444' : '4001';
            // Safe version operates on ports 8444 or 4001, retry there.
            // this will usually only happen when trying to update the base
            // kc-sargas package because it kills its own service
            obj.safeValue = obj.value.replace(/:\d+/, `:${safePort}`);
        }

        if (obj.tunnel_protocol && obj.tunnel_host && obj.tunnel_endpoint) {
            obj.tunnel_url = `${obj.tunnel_protocol}://${obj.tunnel_host}/${obj.tunnel_endpoint}`;
        } else {
            obj.tunnel_url = '';
        }
        return obj;
    }

    private getData(component) {
        if (
            this.groupLoginService.isLoggedIn() &&
            (this.actionService.isAllowAction('kits_inventory', 'scan_kit', 'Allow retrieving Hardware data') ||
                this.actionService.isAllowAction('hospital_settings', 'view_hardware', 'Allow loading hardware data'))
        ) {
            if (this.cache[component]) {
                return Promise.resolve(this.cache[component]);
            } else {
                return this.hardwareResource
                    .hardwareList()
                    .then((data) => {
                        this.cache.hardware = _.map(data.hardware, this.fillHardwareAttributes);
                        this.cache.scanners = _.filter(
                            this.cache.hardware,
                            (scanner) => scanner.hardware_type_category === 'scanner'
                        );
                        this.cache.printers = _.filter(
                            this.cache.hardware,
                            (printer) => printer.hardware_type_category === 'rfid printer'
                        );

                        _.each(this.cache.printers, (printer) => {
                            printer.is_tunnel = printer.tunnel_url && printer.hostname && printer.ip4_port;
                        });

                        const defaultScannerId = this.localStorageService.get('default.scannerId');
                        this.setDefaultScanner(defaultScannerId);
                        const defaultPrinterId = this.localStorageService.get('default.printerId');
                        this.setDefaultPrinter(defaultPrinterId);
                        return this.cache[component];
                    })
                    .catch(() => {
                        return Promise.reject();
                    });
            }
        } else {
            return Promise.reject();
        }
    }
}
