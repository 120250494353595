import { Component } from '@angular/core';

import { ActionService } from '@services/utils/action.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { LocalStorageService } from '@services/storage/local-storage.service';
import { ProductModuleService } from '@services/core/product-module.service';
import { TranslationService } from '@services/utils/translation.service';
import { UserListService } from '@services/core/user-list.service';
import { UserResource } from '@resources/user-resource.service';
import { UserSettingsResource } from '@resources/user-settings-resource.service';

import { User } from '@models/core/user';
import * as _ from 'lodash';

@Component({
    selector: 'user-settings',
    templateUrl: './user-settings.html',
    styleUrls: ['./user-settings.scss'],
})
export class UserSettings {
    //bindings
    canChangePassword: boolean;
    chargeModel: string;
    defaultPage: any;
    hasPIN: boolean;
    lowCreditNotification: boolean;
    pages: any;
    pwd: any;
    pin: any;
    pinPattern: RegExp;
    settingsLoading: boolean = true;
    updatingDefaultPage: boolean;
    user: User;
    userId: number;

    constructor(
        private actionService: ActionService,
        private hospitalInfoService: HospitalInfoService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private localStorageService: LocalStorageService,
        private productModuleService: ProductModuleService,
        private translationService: TranslationService,
        private userListService: UserListService,
        private userResource: UserResource,
        private userSettingsResource: UserSettingsResource
    ) {}

    ngOnInit() {
        this.productModuleService.setModule();
        this.defaultPage = {
            value: this.localStorageService.get('defaultPage') || '',
        };

        this.pages = this.productModuleService.getModules().filter((page) => !page.headerOnly);

        //reset password
        this.pwd = {};
        this.pin = {};
        this.hasPIN = true;
        this.pinPattern = /^\d*$/;
        this.userId = _.parseInt(this.localStorageService.get('userId'));

        this.canChangePassword = this.actionService.isAllowAction(
            'user_settings',
            'change_pwd',
            'Show Reset Password form in Settings'
        );

        this.userSettingsResource.getUserSettings(this.userId).then((data) => {
            this.lowCreditNotification = data.low_credit_notification;
            this.settingsLoading = false;
        });
        this.chargeModel = this.hospitalInfoService.getHospitalSettings().charge_model_type?.name;

        this.userResource.getUser(this.userId).then((data) => {
            this.user = data.user;
            this.hasPIN = data.user.has_epc_pin;
        });
    }

    isResetAllowed = () => this.pwd.currentPwd && this.pwd.newPwd && this.pwd.retype;
    isPINResetAllowed = () => (this.pin.currentPIN || this.pin.currentPwd) && this.pin.newPIN && this.pin.confirmNewPIN;
    canUpdatePIN = () =>
        this.hospitalInfoService.hospitalSetting('has_badges') &&
        this.actionService.isAllowAction('user_settings', 'change_pin', 'User Settings: change pin');
    updateProfile = () => {};

    updateDefaultPage = () => {
        this.updatingDefaultPage = true;
        this.userResource
            .updateUser({
                id: this.userId,
                start_application: this.defaultPage.value,
            })
            .then(() => {
                this.localStorageService.set('defaultPage', this.defaultPage.value);
                this.kcMatSnackBarService.open(
                    SnackBarTypes.SUCCESS,
                    this.translationService.instant('settings.default_page_updated')
                );
            })
            .catch(() => {
                this.kcMatSnackBarService.open(
                    SnackBarTypes.ERROR,
                    this.translationService.instant('settings.could_not_update')
                );
            })
            .finally(() => {
                this.updatingDefaultPage = false;
            });
    };

    resetPIN = () => {
        this.pin.isValid = true;

        if (!this.isPINResetAllowed()) {
            this.pin.isValid = false;
            this.pin.error = this.translationService.instant('settings.pins_incomplete');
            this.pin.confirmNewPIN = '';
        } else if (this.pin.newPIN !== this.pin.confirmNewPIN) {
            this.pin.isValid = false;
            this.pin.error = this.translationService.instant('settings.pins_do_not_match');
            this.pin.newPwd = this.pin.confirmNewPIN = '';
        } else {
            const data: any = {
                id: this.userId,
                epc_pin: this.pin.newPIN,
            };

            if (this.hasPIN) {
                data.current_pin = this.pin.currentPIN;
            } else {
                data.current_password = this.pin.currentPwd;
            }

            this.userResource
                .updateUser(data)
                .then(() => {
                    this.userListService.clear();
                    this.pin = {};
                    if (this.hasPIN) {
                        this.kcMatSnackBarService.open(
                            SnackBarTypes.SUCCESS,
                            this.translationService.instant('settings.pin_updated')
                        );
                    } else {
                        this.kcMatSnackBarService.open(
                            SnackBarTypes.SUCCESS,
                            this.translationService.instant('settings.pin_created')
                        );
                        this.hasPIN = true;
                    }
                })
                .catch((err) => {
                    this.kcMatSnackBarService.open(SnackBarTypes.ERROR, err.message);
                });
        }
    };

    resetPassword = () => {
        this.pwd.isValid = true;

        if (!this.isResetAllowed()) {
            this.pwd.isValid = false;
            this.pwd.error = this.translationService.instant('settings.password_incomplete');
            this.pwd.retype = '';
        } else if (this.pwd.newPwd !== this.pwd.retype) {
            this.pwd.isValid = false;
            this.pwd.error = this.translationService.instant('settings.password_does_not_match');
            this.pwd.newPwd = this.pwd.retype = '';
        } else if (this.pwd.newPwd.length < 6) {
            this.pwd.isValid = false;
            this.pwd.error = this.translationService.instant('settings.password_too_short');
        } else {
            const data: any = {
                id: this.userId,
                password: this.pwd.newPwd,
                current_password: this.pwd.currentPwd,
            };
            this.userResource
                .updateUser(data)
                .then(() => {
                    this.userListService.clear();
                    this.pwd = {};
                    this.kcMatSnackBarService.open(
                        SnackBarTypes.SUCCESS,
                        this.translationService.instant('settings.password_updated')
                    );
                })
                .catch((err) => {
                    this.kcMatSnackBarService.open(
                        SnackBarTypes.ERROR,
                        this.translationService.instant('settings.could_not_update')
                    );
                });
        }
    };

    clearPwdError() {
        this.pwd.isValid = null;
    }
    clearPINError() {
        this.pin.isValid = null;
    }

    pinTextKey = () => {
        if (this.hasPIN) {
            return 'settings.pin.reset_pin';
        } else {
            return 'settings.pin.create_pin';
        }
    };

    pinButtonKey = () => {
        if (this.hasPIN) {
            return 'buttons.reset';
        } else {
            return 'buttons.save';
        }
    };

    toggleLowCreditNotification() {
        this.lowCreditNotification = !this.lowCreditNotification;
    }

    updateLowCreditNotification() {
        const data = {
            low_credit_notification: this.lowCreditNotification,
        };
        this.userSettingsResource
            .setUserSettings(this.userId, data)
            .then((results) => {
                this.kcMatSnackBarService.open(
                    SnackBarTypes.SUCCESS,
                    this.translationService.instant('settings.notification_set')
                );
            })
            .catch((err) => {
                this.kcMatSnackBarService.open(
                    SnackBarTypes.ERROR,
                    this.translationService.instant('settings.could_not_update')
                );
            });
    }
}
