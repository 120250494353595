import { Component, Input, ViewChild, AfterViewInit } from '@angular/core';
import * as _ from 'lodash';

import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';

import { ActionService } from '@services/utils/action.service';
import { ConfigurationProvider } from '@services/config/configuration';
import { GroupInfoService } from '@services/core/group-info.service';
import { GroupLoginService } from '@services/login/group-login.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { LocalStorageService } from '@services/storage/local-storage.service';
import { LoadingSpinnerService, LoadingSpinnerTypes } from '@services/system/loading-spinner.service';
import { TranslationService } from '@services/utils/translation.service';
import { UserListService } from '@services/core/user-list.service';
import { UserResource } from '@resources/user-resource.service';

import { UploadCsvDialog } from '@components/dialogs/upload-csv/upload-csv-dialog';
import { TableComponent } from '@components/common/table-component';

import { User } from '@models/core/user';
import { IdnGroup } from '@models/core/group';

import * as moment from 'moment';

@Component({
    selector: 'admin-users-list',
    templateUrl: './admin-users-list.html',
    styleUrls: ['./admin-users-list.scss'],
})
export class AdminUsersList {
    //bindings
    @Input() idnGroup: IdnGroup;

    actionAllow: any;
    currentUsername: string;
    downloadFileName: string;
    endpoint: string;
    hasMultipleGroups: boolean;
    isIdnGroup: boolean;
    selectedGroup: number;
    users: User[] = [];
    boundRefresh: Function;

    isLoading: boolean = true;
    hasData: boolean = false;

    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[] = ['username', 'first_name', 'last_name', 'role_names_for_group'];
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private actionService: ActionService,
        private configuration: ConfigurationProvider,
        private dialog: MatDialog,
        private groupInfoService: GroupInfoService,
        protected groupLoginService: GroupLoginService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private loadingSpinnerService: LoadingSpinnerService,
        private localStorageService: LocalStorageService,
        private translationService: TranslationService,
        private userListService: UserListService,
        private userResource: UserResource
    ) {}

    ngOnInit() {
        this.isIdnGroup = !_.isEmpty(this.idnGroup);
        this.hasMultipleGroups = this.isIdnGroup && (Object as any).values(this.idnGroup).flat().length > 1;
        this.selectedGroup = this.groupInfoService.getGroupId();
        this.actionAllow = this.actionService.allowModule('user_settings');
        this.getUsersAndRoles();
        this.endpoint = this.configuration.kcEndpointV3();
        this.boundRefresh = this.refreshUsers.bind(this);

        if (!this.allowedToViewRoles()) {
            this.displayedColumns = ['username', 'first_name', 'last_name'];
        }
        this.currentUsername = this.localStorageService.get('userName');
        this.downloadFileName = this.translationService.instant('admin.users.download_file_name', {
            downloadDate: moment().format('YYYY-MM-DD'),
        });
    }

    setTableData(): void {
        this.dataSource = new MatTableDataSourceWithNaturalSort(this.users);
        this.dataSource.sort = this.sort;
        this.isLoading = false;
        this.hasData = !!this.users.length;
    }

    getUsersAndRoles(): void {
        this.refreshUsers(this.selectedGroup);
    }

    refreshUsers(groupId: string | number): void {
        this.selectedGroup = Number(groupId);
        let userResource: Promise<any>;

        userResource = this.userResource.showUsers(this.selectedGroup);
        let userPromise = userResource.then(({ users }) => {
            let selectedGroupUsers: User[] = [];
            users.forEach((user) => {
                const userGroupRoles = user.roles_for_group.find(
                    (groupRoles) => groupRoles.group_id === this.selectedGroup
                );
                if (!!userGroupRoles) {
                    user.role_names_for_group = this.userRoleNames(userGroupRoles);
                    // show users that only have all KC roles
                    if (!!user.role_names_for_group.length) selectedGroupUsers.push(user);
                }
            });
            this.users = selectedGroupUsers;
            this.setTableData();
        });

        this.loadingSpinnerService.spinnerifyPromise(userPromise, LoadingSpinnerTypes.PANEL);
    }

    userRoleNames(groupRoles): string {
        // if the user is not an admin, filter out the BlueSight/ControlCheck roles from the view - (additional logic in the Create/Edit view for the same thing too)
        groupRoles.roles = groupRoles.roles.filter((role) => !role.name.toLowerCase().includes('bluesight'));
        return groupRoles.roles
            .sort((a, b) => {
                return a.inherited - b.inherited || a.name.localeCompare(b.name);
            })
            .map((role) => {
                if (role.inherited) {
                    return `<i>${role.name}</i>`;
                } else {
                    return `<b>${role.name}</b>`;
                }
            })
            .join(', ');
    }

    showUploadModal(): void {
        const uploadDialog = this.dialog.open(UploadCsvDialog, {
            width: '600px',
            height: 'max-content',
            data: { noun: 'user', url: `${this.endpoint}/users/import`, authToken: this.groupLoginService.authToken() },
        });

        uploadDialog.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                const promise = this.userListService
                    .getUsers(true)
                    .then((data) => {
                        this.getUsersAndRoles();
                    })
                    .catch((err) => {
                        if (err.hasOwnProperty('message')) {
                            this.kcMatSnackBarService.open(SnackBarTypes.ERROR, err.message);
                        }
                    });
                this.loadingSpinnerService.spinnerifyPromise(promise, LoadingSpinnerTypes.PANEL);
            }
        });
    }

    showSpinner(): boolean {
        return this.loadingSpinnerService.showSpinner;
    }

    isAllow(action: string, reason: string): boolean {
        if (action === 'profile') {
            return this.actionService.isAllowAction('user_settings', 'view_other_profile', reason);
        } else if (action === 'create') {
            return this.actionService.isAllowAction('user_settings', 'create_user', reason);
        } else if (action === 'import') {
            return this.actionService.isAllowAction('user_settings', 'import_user', reason);
        } else if (action === 'update') {
            return this.actionService.isAllowAction('user_settings', 'update_other_user', reason);
        } else if (action === 'delete') {
            return this.actionService.isAllowAction('user_settings', 'delete_user', reason);
        } else if (action === 'change_pwd') {
            return this.actionService.isAllowAction('user_settings', 'change_pwd', reason);
        } else if (action === 'change_other_pwd') {
            return this.actionService.isAllowAction('user_settings', 'change_other_pwd', reason);
        } else if (action === 'change_pin') {
            return this.actionService.isAllowAction('user_settings', 'change_pin', reason);
        } else if (action === 'change_other_pin') {
            return this.actionService.isAllowAction('user_settings', 'change_other_pin', reason);
        } else {
            return false;
        }
    }

    allowedToViewRoles(): boolean {
        return (
            this.actionService.isAllowAction('user_settings', 'view_other_profile', 'Load roles list data') &&
            (this.actionService.isAllowAction('user_settings', 'view_own_profile', 'Load roles list data') ||
                this.actionService.isAllowAction('user_settings', 'view_users', 'Load roles list data'))
        );
    }
}
