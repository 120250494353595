import { Injectable } from '@angular/core';
import { LocalStorageService as NgLocalStorageService } from 'angular-2-local-storage';

/**
 * Tie over class dealing with upgrade transition so calls are all in one place
 */
@Injectable()
export class LocalStorageService {
    public static readonly PREFIX = 'kcui';

    constructor(private ngLocalStorageService: NgLocalStorageService) {}

    get(itemName: string) {
        const value = localStorage.getItem(`${LocalStorageService.PREFIX}.${itemName}`);
        if (!value) {
            return value;
        }

        if (['"', '{', '['].includes(value.charAt(0))) {
            return JSON.parse(value);
        }

        if (value === 'null') {
            return null;
        }

        return value;
    }

    set(itemName: string, value: any) {
        localStorage.setItem(`${LocalStorageService.PREFIX}.${itemName}`, JSON.stringify(value));
    }

    remove(itemName: string) {
        localStorage.removeItem(`${LocalStorageService.PREFIX}.${itemName}`);
    }

    clearAll(itemMatch: string) {
        this.ngLocalStorageService.clearAll(itemMatch);
    }
}
