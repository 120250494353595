import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { compare } from 'natural-orderby';

export class MatTableDataSourceWithNaturalSort<T> extends MatTableDataSource<T> {
    sortData: (data: T[], sort: MatSort) => T[] = (data: T[], sort: MatSort): T[] => {
        const active = sort.active;
        const direction = sort.direction;
        if (!active || direction === '') {
            return data;
        }

        return data.sort((a, b) => {
            const valueA = this.sortingDataAccessor(a, active);
            const valueB = this.sortingDataAccessor(b, active);

            return compare({ order: direction })(valueA, valueB);
        });
    };
}
